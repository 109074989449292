import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Seo from 'components/SEO'
import PageContent from "components/Shared/_PageContent"
import AboutNav from 'components/Public/AboutNav'


import styled from 'styled-components'
const Wrapper = styled.div`
  p {
    --text-align: left;
  }
`

const About = ({ data, location }) => {
  const {
    content: { body },
    seo,
  } = data
  const { title, description } = seo.frontmatter.about

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <AboutNav location={location} />
      <Wrapper>
        <MDXRenderer>{body}</MDXRenderer>
      </Wrapper>
    </PageContent>
  )
}

export default About

export const query = graphql`
  {
    content: mdx(frontmatter: { type: { eq: "page" }, slug: { eq: "about" } }) {
      body
    }

    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        about {
          description
          title
        }
      }
    }
  }
`
